import React from "react";
import Layout from "../components/Layout/Layout";
import PageContainer from "../components/PageContainer/PageContainer";
import FluidImage from "../components/FluidImage/FluidImage";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import Heading from "../components/PageContainer/Heading";
import Seo from "../components/seo";
import { graphql } from "gatsby";

const priser = ({ data: { page } }) => {
  const { text, title, image } = page.header_twocol.header;
  const priser = page.priser.price;
  return (
    <Layout>
      <Seo
        title={title}
        description={text}
        image={image?.localFile?.childImageSharp.original?.src}
      />
      <PageContainer
        content={<FluidImage src={image?.localFile} title={title} />}
      >
        <div className="flex flex-col gap-6 w-full">
          <Heading title={title} text={text} />
          <List>
            {priser.map((pris, index) => (
              <ListItem key={index} first={pris.value} second={pris.content} />
            ))}
          </List>
        </div>
      </PageContainer>
    </Layout>
  );
};

export default priser;

export const pageQuery = graphql`
  query PricingPage {
    page: wpPage(title: { eq: "Priser" }) {
      id
      header_twocol {
        header {
          text
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      priser {
        price {
          value
          content
        }
      }
    }
  }
`;
